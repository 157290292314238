@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    @apply h-full;
}

html {
    @apply bg-app-bg;
}

body {
    font-family: 'DM Sans', sans-serif;
}

.hide-scrollbar::-webkit-scrollbar {
    display: none;
}

.hide-scrollbar {
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.tab-active {
    @apply !text-black border-b-2 border-[#14b3d4] font-bold;
}

.tab-active-m {
    @apply !text-black;
}

.dashed {
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='5' ry='5' stroke='%23DFDFDFFF' stroke-width='2' stroke-dasharray='20%2c 20' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
    border-radius: 5px;
}

@media screen {
    .print-header {
        display: none;
    }
}

@media print {
    body {
        background: none;
        padding-left: 40px;
        padding-right: 40px;
        padding-bottom: 40px;
    }

    .no-print, .no-print * {
        display: none !important;
    }
}

select {
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAQCAYAAAAMJL+VAAAABGdBTUEAALGPC/xhBQAAAQtJREFUOBG1lEEOgjAQRalbGj2OG9caOACn4ALGtfEuHACiazceR1PWOH/CNA3aMiTaBDpt/7zPdBKy7M/DCL9pGkvxxVp7KsvyJftL5rZt1865M+Ucq6pyyF3hNcI7Cuu+728QYn/JQA5yKaempxuZmQngOwEaYx55nu+1lQh8GIatMGi+01NwBcEmhxBqK4nAPZJ78K0KKFAJmR3oPp8+Iwgob0Oa6+TLoeCvRx+mTUYf/FVBGTPRwDkfLxnaSrRwcH0FWhNOmrkWYbE2XEicqgSa1J0LQ+aPCuQgZiLnwewbGuz5MGoAhcIkCQcjaTBjMgtXGURMVHC1wcQEy0J+Zlj8bKAnY1/UzDe2dbAVqfXn6wAAAABJRU5ErkJggg==');
    background-size: 0.7rem;
    background-position: right 0.7rem center;
    background-repeat: no-repeat;
    @apply appearance-none
}

.card-shadow {
    box-shadow: 0px 2px 6px -2px rgba(0, 0, 0, 0.15);
}
