@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
    .btn {
        @apply text-lg flex justify-center items-center gap-2 rounded-lg shadow-md hover:opacity-90 px-6 py-3 w-full disabled:opacity-50 disabled:cursor-not-allowed transition duration-300 ease-in-out
    }

    .app-input-label {
        @apply font-semibold mb-1 text-app-dark-text
    }

    .app-input {
        @apply border border-[#F2F2F2] rounded px-4 py-3 w-full bg-white disabled:text-gray-400 disabled:cursor-not-allowed;
        box-shadow: 0px 2px 6px -2px rgba(0, 0, 0, 0.15);
    }
}

.btn-main {
    @apply btn bg-[#108095] text-white cursor-pointer z-20
}

.btn-danger {
    @apply btn bg-red-500 text-white hover:bg-red-700
}

.btn-danger-alt {
    @apply btn bg-white text-red-500 border border-red-500 hover:bg-red-500 hover:text-white
}
